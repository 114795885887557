import * as React from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';
import { Icon } from '../icon';

const IconContainer = styled(Box)`
  svg {
    stroke: currentcolor;
    animation-duration: 750ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: progress-spin;
    width: 100%;
  }
  @keyframes progress-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingScreen: React.FC<{ message?: string }> = ({ message }) => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    sx={{
      width: '100vw',
      height: '100vh',
      background: 'linear-gradient(180deg, #06070A 0%, #1A355D 198.49%)',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 100,
    }}
  >
    <Grid item>
      <IconContainer>
        <Icon iconName="spinner" size="48px" />
      </IconContainer>
      {message && <Typography variant="body1">{message}</Typography>}
    </Grid>
  </Grid>
);

export default LoadingScreen;
