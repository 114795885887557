import * as Sentry from '@sentry/gatsby';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { useHandleCheckout } from '../hooks/useShopify';
import { useGlobalState, User } from '../components/GlobalState';
import LoadingScreen from '../components/loading-screen';
import useGetHpidProfile from '../hooks/useGetHpidProfile';
import theme from '../theme';
import Notification from '../components/Notification';

export interface AuthProps {
  pageContext: {
    id: string;
    checkout: boolean;
    user: boolean;
  };
}

const Content = styled('div')`
  padding: 2rem;
`;

function getCookie(name: string): string {
  if (typeof document === 'undefined') {
    return '';
  }
  const id = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(id) === 0) {
      return cookie.substring(id.length, cookie.length);
    }
  }
  return '';
}

function setCookie(name: string, value: string, expiresAt: Date): void {
  if (typeof document === 'undefined') {
    return;
  }
  const expires = `expires=${expiresAt.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

function getAndRemoveCookie(name: string): string {
  const cookie = getCookie(name);
  setCookie(name, '', new Date(0));
  return cookie;
}

const Auth: React.FC<AuthProps> = ({
  pageContext: { id, checkout, user: isUserRoute },
}) => {
  const hpid = getAndRemoveCookie('hpid');
  const accessToken = getAndRemoveCookie('accessToken');
  const refreshToken = getAndRemoveCookie('refreshToken');
  const [{ user, cart, cartSummary }, dispatch] = useGlobalState();
  const [checkoutError, setCheckoutError] = useState<boolean>(false);

  const [getHpidProfile] = useGetHpidProfile();
  const [handleCheckout] = useHandleCheckout();

  const handleAuth = async () => {
    if (!user && hpid) {
      const newUser: User = {
        id: hpid,
      };

      try {
        const { data } = await getHpidProfile();
        newUser.profile = {
          ...data.customer.hpidProfile,
          addresses: data.customer.addresses,
        };
      } catch (error) {
        Sentry.captureException(error);
      }

      dispatch({
        type: 'USER_LOGIN',
        user: newUser,
      });

      // HP UDL Analytics
      if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'e_userLogin',
        });
      }
      return newUser;
    }

    return user;
  };

  const handleUser = async () => {
    const currentUser = await handleAuth();
    if (currentUser) {
      if (checkout) {
        try {
          await handleCheckout(cart, cartSummary, currentUser);
        } catch (error) {
          Sentry.captureException(error);
          setCheckoutError(true);
          setTimeout(() => {
            navigate('/');
          }, 10000);
        }
      } else if (isUserRoute) {
        navigate(`/user/${id}`);
      }
    }
  };

  useEffect(() => {
    if (hpid && accessToken && refreshToken && typeof Storage !== 'undefined') {
      localStorage?.setItem(
        'hp-xy-id',
        JSON.stringify({ hpid, token: accessToken, refresh: refreshToken }),
      );
    }

    handleUser();

    if (!checkout && !isUserRoute) {
      navigate('/');
    }
  }, []);

  return (
    <Layout hideHeader hideFooter>
      <SEO title="Sign-in" description="Account Sign-in" />
      <Content>
        {checkoutError ? (
          <Notification
            variant="standard"
            severity="error"
            sx={{
              paddingTop: '0px',
              color: theme.palette.error.main,
            }}
          >
            Error during checkout. Please try again later.
          </Notification>
        ) : (
          <LoadingScreen message="Signing you in..." />
        )}
      </Content>
    </Layout>
  );
};

export default Auth;
