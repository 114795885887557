import { gql, useLazyQuery } from '@apollo/client';
import middlewareClient from '../apollo/middlewareClient';

const useGetHpidProfile = () => {
  const GET_HPID_PROFILE = gql`
    query HpidProfile {
      customer {
        hpidProfile {
          id
          countryResidence
          identityProvider
          emails {
            primary
            value
            type
            verified
            accountRecovery
          }
          enabled
          legalZone
          locale
          name {
            givenName
            middleName
            familyName
          }
          phoneNumbers {
            accountRecovery
            number
            primary
            type
            verified
            id
          }
          type
          userName
        }
        addresses {
          address1
          address2
          city
          firstName
          lastName
          phone
          province
          zip
          country
          countryCodeV2
        }
      }
    }
  `;

  return useLazyQuery(GET_HPID_PROFILE, {
    client: middlewareClient,
  });
};

export default useGetHpidProfile;
